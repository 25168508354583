<template>
  <div>
    <b-card>
      <progress-bar-status :progress="3" :status="aktivasiAftaf.status" />
    </b-card>
    <div class="bg-white rounded mt-3 p-sm-32 mx-0">
      <div class="d-flex align-items-start justify-content-between">
        <h2>Reaksi Pendonor</h2>
        <div>
          <b-button v-if="!isEditing" @click="isEditing = true">Edit</b-button>
          <b-button v-if="isEditing" variant="danger" @click="cancelEdit"
            >Batal</b-button
          >
          <b-button
            v-if="isEditing"
            class="ml-1"
            variant="success"
            @click="saveChanges"
            >Simpan</b-button
          >
        </div>
      </div>
      <hr />

      <h3>
        Golongan Darah:
        {{
          aktivasiAftaf.blood_type.replace(/\+|-/g, "") +
          (aktivasiAftaf.blood_type.includes("+") ? " Pos" : " Neg")
        }}
      </h3>
      <b-row>
        <b-col>
          <h4>Keterangan Donasi</h4>
          <b-form-group label="Status">
            <b-form-checkbox v-model="isDone" :disabled="!isEditing" switch>
              {{ isDone ? "Diterima" : "Ditolak" }}
            </b-form-checkbox>
          </b-form-group>
          <b-form-group label="No. Kantong">
            <b-form-input
              v-model="aktivasiAftaf.blood_bag_number"
              :disabled="!isEditing"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Jam Penusukan">
            <b-row>
              <b-col>
                <b-form-timepicker
                  v-model="aktivasiAftaf.venipuncture_start_time"
                  :disabled="!isEditing"
                  placeholder="Mulai"
                />
              </b-col>
              <b-col>
                <b-form-timepicker
                  v-model="aktivasiAftaf.venipuncture_end_time"
                  :disabled="!isEditing"
                  placeholder="Selesai"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label="Cuci Lengan/Lap Tisu">
            <b-form-radio-group
              v-model="aktivasiAftaf.antiseptic_wipe"
              :disabled="!isEditing"
            >
              <b-form-radio value="1">Sudah</b-form-radio>
              <b-form-radio value="0">Belum</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col>
          <h4>Reaksi Donor</h4>
          <b-form-group label="Reaksi Pengambilan">
            <b-form-radio-group
              v-model="uptake_reaction"
              :disabled="!isEditing"
            >
              <b-form-radio value="lancar">Lancar</b-form-radio>
              <b-form-radio value="tidak-lancar">Tidak Lancar</b-form-radio>
              <b-form-radio value="stop">Stop</b-form-radio>
            </b-form-radio-group>
            <b-form-input
              v-if="uptake_reaction === 'stop'"
              v-model="uptake_reaction_detail"
              :disabled="!isEditing"
              placeholder="Isi Detail Reaksi Pengambilan"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Reaksi Samping">
            <b-form-radio-group v-model="side_effect" :disabled="!isEditing">
              <b-form-radio value="tidak ada">Tidak Ada</b-form-radio>
              <b-form-radio value="hematoma">Hematoma</b-form-radio>
              <b-form-radio value="pusing">Pusing</b-form-radio>
              <b-form-radio value="muntah">Muntah</b-form-radio>
              <b-form-radio value="lain-lain">Lainnya</b-form-radio>
            </b-form-radio-group>
            <b-form-input
              v-if="side_effect === 'lain-lain'"
              v-model="side_effect_detail"
              :disabled="!isEditing"
              placeholder="Isi Detail Reaksi Samping"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Alasan Penolakan" v-if="isDone === false">
            <b-form-select
              v-model="aktivasiAftaf.reason_rejected"
              :disabled="!isEditing"
            >
              <b-form-select-option value="mislek">Mislek</b-form-select-option>
              <b-form-select-option value="tidak jadi"
                >Tidak Jadi</b-form-select-option
              >
              <b-form-select-option value="lain-lain"
                >Lain-lain</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Catatan Penolakan"
            v-if="
              isDone === false && aktivasiAftaf.reason_rejected === 'lain-lain'
            "
          >
            <b-form-textarea
              v-model="aktivasiAftaf.note"
              :disabled="!isEditing"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ProgressBarStatus from "@/view/main/dashboards/registrationdonation/components/common/ProgressBarStatus.vue";
import manageAftafAPI from "../../../../../../api/manageAftaf/manageAftafAPI";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BFormRadio,
  BFormRadioGroup,
  BFormTimepicker,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  name: "DetailAftaf",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormRadio,
    BFormRadioGroup,
    BFormSelectOption,
    BFormTimepicker,
    BFormCheckbox,
    ProgressBarStatus,
  },
  data() {
    return {
      aktivasiAftaf: null,
      isEditing: false,
      originalData: null,
      isDone: false,
      uptake_reaction: null,
      uptake_reaction_detail: null,
      side_effect: null,
      side_effect_detail: null,
    };
  },
  mounted() {
    this.fetchDetailAftaf();
  },
  watch: {
    "aktivasiAftaf.status"(newStatus) {
      this.isDone = newStatus === "Done" || newStatus === "Approved";
    },
  },
  methods: {
    async fetchDetailAftaf() {
      const { data } = await manageAftafAPI.getDetail(this.$route.params.id);
      this.aktivasiAftaf = data.data;
      this.originalData = { ...this.aktivasiAftaf };
      this.isDone = ["Done", "Approved"].includes(data.data.status);
      this.uptake_reaction =
        data.data.uptake_reaction === "stop"
          ? data.data.uptake_reaction
          : ["lancar", "tidak-lancar"].includes(data.data.uptake_reaction)
          ? data.data.uptake_reaction
          : null;
      this.uptake_reaction_detail =
        this.uptake_reaction === "stop" ? data.data.uptake_reaction : null;
      const sideEffectOptions = ["tidak ada", "hematoma", "pusing", "muntah"];
      this.side_effect = sideEffectOptions.includes(data.data.side_effect)
        ? data.data.side_effect
        : "lain-lain";
      this.side_effect_detail =
        this.side_effect === "lain-lain" ? data.data.side_effect : null;
    },
    async saveChanges() {
      try {
        const updatedFields = {
          uptake_reaction:
            this.uptake_reaction === "stop"
              ? this.uptake_reaction_detail
              : this.uptake_reaction,
          side_effect:
            this.side_effect === "lain-lain"
              ? this.side_effect_detail
              : this.side_effect,
          status: this.isDone ? "Done" : "Rejected",
          reason_rejected: this.isDone
            ? ""
            : this.side_effect !== "lain-lain"
            ? ""
            : this.aktivasiAftaf.reason_rejected,
          note: this.isDone
            ? ""
            : this.side_effect === "lain-lain"
            ? ""
            : this.aktivasiAftaf.note,
        };
        await manageAftafAPI.edit({
          ...this.aktivasiAftaf,
          ...updatedFields,
        });
        this.aktivasiAftaf = {
          ...this.aktivasiAftaf,
          ...updatedFields,
        };
        this.isEditing = false;
        this.$bvToast.toast("Changes saved successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } catch (error) {
        this.$bvToast.toast("Failed to save changes", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    cancelEdit() {
      this.aktivasiAftaf = { ...this.originalData };
      this.isEditing = false;
    },
  },
};
</script>

<style scoped>
label {
  margin: 0;
  color: #636e72;
}
</style>
